import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import {Button, Card} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

function App() {

    const navigate = useNavigate();
  return (
      <Container className="p-3 vh-100">
          <Card>
              <Card.Body>
                  <Card.Title>Giochi</Card.Title>
                  <Card.Text>
                  </Card.Text>
                  <Button variant="primary" onClick={()=> navigate('/play_one')}>Start</Button>
              </Card.Body>
          </Card>
      </Container>
  );
}

export default App;
