import React, {useEffect, useState} from 'react';
import {Badge, Button, Col, Row} from "react-bootstrap";
import {useDrag, useDrop} from "react-dnd";
import DraggedElement from "./DraggedElement";


interface Element {
    id: string,
    obj: string,
    dropBoxId: string
}


interface DropBoxInterface {
    elemntsDropped: Element[],
    setElementsDropped: Function,
    id: string
    srcImg: string
}


function DropBox({elemntsDropped, setElementsDropped, id, srcImg}: DropBoxInterface) {

    //const [isFull, setIsFull] = useState(false);

    //const [isSuccess, setIsSuccess] = useState<boolean | null>(null);

    const [{isOver}, drop] = useDrop(
        () => ({
            accept: 'badge',
            drop: (item: Element, monitor) => {

                console.log('idMGS', id)
                console.log('elements', elemntsDropped)
                setElementsDropped((oldArray: Element[]) => [...oldArray, {...item, dropBoxId: id}])

            },
            collect: (monitor) => ({
                isOver: !!monitor.isOver()
            }),
            canDrop: (item, monitor) => {
                return !isFull()
            }
        }),
        [elemntsDropped, id]
    )


    const isSuccess = function (idImg: string) {

        const elementOK = elemntsDropped.filter(item => {
            return (item.id === idImg && item.dropBoxId === id)
        });

        if (elementOK.length > 0) {
            return true;
        }


        const elementKO = elemntsDropped.filter(item => {
            return (item.id !== idImg && item.dropBoxId === id)
        });
        if (elementKO.length > 0) {
            return false;
        }

        return null;
    }



    const isFull = function () {

        const elementOK = elemntsDropped.filter(item => {
            return (item.dropBoxId === id)
        });

        if (elementOK.length > 0) {
            return true;
        }

        return false;
    }

    const handleCLear = function (id: string) {

        let filteredArray = elemntsDropped.filter(item => item.dropBoxId !== id)
        setElementsDropped(filteredArray ? filteredArray : []);
       // setIsFull(false);
        //setIsSuccess(null);

    }

    return (
        <React.Fragment>
            <Col sm={4}>
                <div className="d-flex">
                    <div className={`${isOver ? 'bg-secondary' : 'bg-white'} border`}
                         style={{
                             textAlign: 'center',
                             width: '200px',
                             height: '15vh',
                             minHeight: '15vh'
                         }} ref={drop}>
                        {elemntsDropped.map((item: Element) => {
                            return (item.dropBoxId === id ? <DraggedElement id={item.id} obj={item.obj}/> : '')
                        })}
                    </div>
                    {isSuccess(id) === true ? <span className="ms-2 text-success fw-bolder">Corretto!</span> : ''}
                    {isSuccess(id) === false ?
                        <React.Fragment>
                            <span className="ms-2 text-danger fw-bolder">Non Corretto!</span>
                            <Button variant="outline-primary" className="m-2"
                                    onClick={() => handleCLear(id)}>Riprova</Button>
                        </React.Fragment>
                        : ''}
                </div>
            </Col>
            <Col sm={4} style={{height: '15vh'}}>
                <img src={srcImg} className="img-fluid h-100"/>
            </Col>
        </React.Fragment>
    )
}


export default DropBox;
