import React from 'react';
import {Badge} from "react-bootstrap";
import {useDrag} from "react-dnd";


interface DraggetElementInterface {
    id: string,
    obj: string
}


function DraggedElement({id, obj}: DraggetElementInterface) {


    return (
        <img src={obj} className="img-fluid h-100"/>
    )
}


export default DraggedElement;
