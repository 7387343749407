import React, {useEffect, useState} from 'react';

import {Col, Row, Stack, Container, Navbar, Nav, Button} from "react-bootstrap";
import {useDrag, useDrop} from "react-dnd";
import DraggableElement from "./elements/DraggableElement";
import DropBox from "./elements/DropBox";
import img1 from "./../../assets/PlayOne/img1.jpg"
import img2 from "./../../assets/PlayOne/img2.jpg"
import img3 from "./../../assets/PlayOne/img3.jpg"
import img4 from "./../../assets/PlayOne/img4.jpg"
import box1 from "./../../assets/PlayOne/box1.jpg"
import box2 from "./../../assets/PlayOne/box2.jpg"
import box3 from "./../../assets/PlayOne/box3.jpg"
import box4 from "./../../assets/PlayOne/box4.jpg"

interface Element {
    id: string,
    obj: string,
    dropBoxId: string
}


function PlayOne() {

    const [elemntsDropped, setElementsDropped] = useState<Element[]>([]);

    const isElementDropped = (id: string) => elemntsDropped.filter(e => e.id === id).length > 0

    const [items, setItems] = useState<string[]>([])
    const [itemsBox, setItemsBox] = useState<string[]>([])

    useEffect(() => {

      reset()

    }, [])

    function reset() {
        var array = ['1', '2', '3', '4'];
        var shuffledArray = array.sort((a, b) => 0.5 - Math.random());
        setItems(shuffledArray);

        var arrayBox = ['1', '2', '3', '4'];
        var shuffledArrayBox = arrayBox.sort((a, b) => 0.5 - Math.random());
        setItemsBox(shuffledArrayBox);
    }

    function getBoxName(name: string) {
        switch (name) {
            case '1':
                return box1
            case '2':
                return box2
            case '3':
                return box3
            case '4':
                return box4
            default:
                return ''
        }

    }

    function getImgName(name: string) {
        switch (name) {
            case '1':
                return img1
            case '2':
                return img2
            case '3':
                return img3
            case '4':
                return img4
            default:
                return ''
        }

    }

    return (
        <React.Fragment>
            <Navbar bg="warning" sticky="top">
                <Container className="justify-content-start">
                    <Nav.Item onClick={() => {
                        setElementsDropped([]);
                        reset()
                    } }><Button variant="outline-primary">Reset</Button></Nav.Item>
                    <p className="ms-4 text-primary fw-bold">Trascina l'immagine nel riquadro corretto.</p>
                </Container>
            </Navbar>

            <Container className="container-fluid p-4">
                <Stack gap={5}>
                    <Row>
                        <DraggableElement isDropped={isElementDropped(items[0])} id={items[0]}
                                          setElementsDropped={setElementsDropped}
                                          obj={getImgName(items[0])}/>
                        <DropBox elemntsDropped={elemntsDropped} setElementsDropped={setElementsDropped}
                                 id={itemsBox[0]}
                                 srcImg={getBoxName(itemsBox[0])}/>
                    </Row>
                    <Row>
                        <DraggableElement isDropped={isElementDropped(items[1])} id={items[1]}
                                          setElementsDropped={setElementsDropped}
                                          obj={getImgName(items[1])}/>
                        <DropBox elemntsDropped={elemntsDropped} setElementsDropped={setElementsDropped}
                                 id={itemsBox[1]}
                                 srcImg={getBoxName(itemsBox[1])}/>
                    </Row>
                    <Row>
                        <DraggableElement isDropped={isElementDropped(items[2])} id={items[2]}
                                          setElementsDropped={setElementsDropped}
                                          obj={getImgName(items[2])}/>
                        <DropBox elemntsDropped={elemntsDropped} setElementsDropped={setElementsDropped}
                                 id={itemsBox[2]}
                                 srcImg={getBoxName(itemsBox[2])}/>
                    </Row>
                    <Row>
                        <DraggableElement isDropped={isElementDropped(items[3])} id={items[3]}
                                          setElementsDropped={setElementsDropped}
                                          obj={getImgName(items[3])}/>
                        <DropBox elemntsDropped={elemntsDropped} setElementsDropped={setElementsDropped}
                                 id={itemsBox[3]}
                                 srcImg={getBoxName(itemsBox[3])}/>
                    </Row>

                </Stack>
            </Container>
        </React.Fragment>
    )
}


export default PlayOne;
