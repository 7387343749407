import React from 'react';
import {Badge, Col} from "react-bootstrap";
import {useDrag} from "react-dnd";


interface DraggableElementInterface {
    isDropped: boolean,
    id: string,
    setElementsDropped: Function
    obj: string
}


function DraggableElement({isDropped, id, setElementsDropped, obj}: DraggableElementInterface) {


    const [{isDragging}, drag] = useDrag(() => ({
        type: 'badge',
        item: {id: id, obj: obj},
        collect: monitor => ({
            isDragging: monitor.isDragging()
        }),
        canDrag: !isDropped,
    }), [isDropped, id])


    return (
        <Col sm={4} style={{height: '15vh'}}>
            <img src={obj} className={"img-fluid h-100"} ref={drag} style={{opacity: isDropped ? '0.4' : '1'}}/>
        </Col>
    )
}


export default DraggableElement;
